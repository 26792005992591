<template>
  <div
    class="grid md:grid-cols-2 overflow-hidden"
    :class="{ 'border border-gray-300 rounded-br-3xl': !popup }"
  >
    <div class="relative hidden md:block">
      <NuxtImg
        width="5000"
        loading="lazy"
        alt="Bild Tim Schäling"
        class="w-full h-full object-cover"
        src="/images/schaeling-tim.jpg"
      />
      <div class="p-6 bg-primary absolute bottom-0 -right-8 rounded-br-3xl w-60 text-white">
        <div class="text-xl font-bold uppercase">Bester Service</div>
        <div class="text-xl font-bold uppercase">Beste Beratung</div>
        <div class="text-xl font-bold uppercase">Bester Tim</div>
        <p class="text-xs mt-2">Ich bin kein Avatar, ich begegne Ihnen ganz persönlich.</p>
      </div>
    </div>

    <div class="p-4 md:p-16 bg-white">
      <FormWrapper ref="form" :keypress-enter="submitForm">
        <div class="grid gap-4">
          <div class="flex gap-4 items-center mb-4">
            <NuxtImg
              width="5000"
              loading="lazy"
              alt="Avatar Tim Schäling"
              class="size-16 rounded-full object-cover md:hidden"
              src="/images/schaeling-tim.jpg"
            />
            <div>
              <div class="uppercase text-sm lg:text-base">TIM - Ihr persönlicher Service-Scout</div>
              <FrontpageHeadline level="3"> Kontakt </FrontpageHeadline>
            </div>
          </div>

          <div v-if="!account" class="grid md:grid-cols-2 gap-x-4 gap-y-2 mb-4">
            <div class="flex flex-col">
              <label for="firstname" class="text-sm font-medium">
                Vorname <span class="text-red-500">*</span>
              </label>
              <FormInput v-model="formData.firstname" required name="firstname" id="firstname" />
            </div>

            <div class="flex flex-col">
              <label for="lastname" class="text-sm font-medium">
                Nachname <span class="text-red-500">*</span>
              </label>
              <FormInput v-model="formData.lastname" required name="lastname" id="lastname" />
            </div>

            <div class="flex flex-col col-span-full">
              <label for="email" class="text-sm font-medium">
                E-Mail <span class="text-red-500">*</span>
              </label>
              <FormInput v-model="formData.email" required name="email" id="email" />
            </div>
          </div>

          <FormTextarea
            v-model="formData.message"
            label="Ihre Nachricht"
            name="message"
            placeholder="Hallo Tim, ich habe eine Frage zu..."
            required
            :rows="rows"
          />
          <p class="text-xs text-gray-500">
            * Pflichtfeld – Bitte füllen Sie alle erforderlichen Felder aus, um fortzufahren.
          </p>
          <div class="flex flex-col">
            <FrontpageButton class="mx-auto md:mr-0" :loading label="senden" @click="submitForm" />
            <div class="mx-auto md:mr-0 mt-8 font-medium">
              Mobil:
              <a href="tel:+4915733191255" class="cursor-pointer hover:underline">
                +49 (0) 157 33 19 12 55
              </a>
            </div>
            <div class="mx-auto md:mr-0 mt-2 font-medium">
              E-Mail:
              <a href="mailto:info@kontordk.de" class="cursor-pointer hover:underline">
                info@kontordk.de</a
              >
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  </div>
</template>

<script setup>
defineProps({
  popup: {
    type: Boolean,
    default: false,
  },
});

const rows = ref(4);
const form = ref(null);
const loading = ref(false);
const formData = reactive({
  firstname: "",
  lastname: "",
  email: "",
  message: "",
});

const { account, layout } = useStore();
const { addNotification } = useNotification();

async function submitForm() {
  loading.value = true;

  try {
    await $fetch("/api/frontpage/contact/form-submit", {
      headers: useRequestHeaders(),
      method: "POST",
      body: {
        form: {
          firstname: formData.firstname || undefined,
          lastname: formData.lastname || undefined,
          email: formData.email || undefined,
          message: formData.message || undefined,
        },
      },
    });

    layout.value.scout = false;

    addNotification({
      type: "success",
      text: "Ihre Nachricht ist bei uns eingegangen.",
    });

    setTimeout(() => {
      formData.firstname = "";
      formData.lastname = "";
      formData.email = "";
      formData.message = "";
    }, 500);
  } catch (error) {
    form.value.handleErrors(error.data.message);
  } finally {
    setTimeout(() => {
      loading.value = false;
    }, 500);
  }
}
defineExpose({
  rows,
  formData,
});
</script>
